import { useEffect, useState } from 'react'

import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'
import auth from '@itau-loans-www/shopping/src/services/http/auth'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import services from './services'

const useDocumentation = () => {
  const { product } = useProposal()
  const [statesOptions, setStatesOptions] = useState()
  const [agenciesOptions, setAgenciesOptions] = useState()
  const { changeLeadData } = useLead()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [getLoading, setGetLoading] = useState(true)

  const productCode = product?.proposal?.productCode

  useEffect(() => {
    analytics.track('pageLoad', {
      currentPage: 'proposta/formalizacao-documento',
      customLayer: {
        description: productCode
      }
    })
    auth({})
      .then(async () => {
        setGetLoading(true)
        return (
          http.formalization.getStates().then((data) => {
            const options = data.map((item) => {
              return {
                label: item['state_name'],
                value: item['state_id'],
                state_uf: item['state_uf']
              }
            })

            setStatesOptions(options)
          }),
          services.getLeadDocument().then(({ previous_step }) => {
            changeLeadData({
              previousStep: previous_step
            })
          }),
          services.getIdentityAgencies().then((data) => {
            const options = data.map((item) => {
              return {
                label: item['identity_agency_name'],
                value: item['identity_agency_id']
              }
            })

            setAgenciesOptions(options)
          })
        )
      })
      .finally(() => setGetLoading(false))
  }, [])

  const onSubmit = (value) => {
    analytics.track('buttonClick', {
      currentPage: 'proposta/formalizacao-documento',
      detail: 'click-step-formalizacao-documento',
      customLayer: {
        description: productCode
      }
    })
    changeLeadData({
      document_data: {
        ...value
      }
    })

    setSubmitLoading(true)

    services
      .putLeadDocument(value)
      .then(({ next_step }) => {
        goTo(next_step)
      })
      .catch((error) => {
        console.log({ error })
      })
      .finally(() => setSubmitLoading(false))
  }

  return {
    onSubmit,
    statesOptions,
    agenciesOptions,
    submitLoading,
    getLoading
  }
}

export default useDocumentation
